import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Master from "./partials/master";
import { CircularProgress, Typography, Box } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "./UserContext";

const CircularProgressWithLabel = ({ value }) => {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
                variant="determinate"
                value={value}
                sx={{
                    color: "#09a381",
                    width: "120px !important",
                    height: "120px !important",
                }}
                size={120}
                thickness={6}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="h6" component="div" color="white">
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

const DefaultDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const user = useUser();
    const userData = user?.user;
    const residentData = user?.residentDetails;
    const residentError = user?.residentError;
    const residentStatus = user?.residentStatus;
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [openItem, setOpenItem] = useState(null);

    const handleToggle = (id) => {
        setOpenItem(openItem === id ? null : id);
    };

    useEffect(() => {
        if (!token) {
            navigate("/login");
            return;
        }

        // Check if specific keys exist in localStorage
        const hasRequiredLocalStorageData =
            localStorage.getItem("resident") &&
            localStorage.getItem("user") &&
            localStorage.getItem("residentTimestamp") &&
            localStorage.getItem("userTimestamp") &&
            localStorage.getItem("token");

        if (hasRequiredLocalStorageData) {
            setLoading(false); // Hide the loader
            setProgress(100); // Set progress to 100%
            return;
        }
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev < 50) return prev + 10;
                if (prev < 100) return prev + 0.5;
                return 100;
            });
        }, 300);

        return () => {
            clearInterval(interval);
        };
    }, [token, navigate]);

    useEffect(() => {

        if (residentData.length > 0 && residentStatus === 200 && userData) {
            setLoading(false);
            setProgress(100);
        }

        if (residentData.length === 0 && residentStatus === 200 && userData) {
            setLoading(false);
            setProgress(100);
        }

        if (residentError) {
            setLoading(false);
            setProgress(100);
        }
    }, [residentStatus, userData, residentError]);


    return (
        <Master>
            {loading ? (
                 <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    backdropFilter: 'blur(4px)',
                    zIndex: 9999,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    <CircularProgressWithLabel value={progress} />
                </div>
            ) : (
                <div className="content">
                    <div className="dashboard-content">
                        <div className="dash-heading">
                            <h1 className="dash-heading-text">
                                Welcome to AlfBoss, {userData?.user?.first_name} {userData?.user?.last_name}!
                            </h1>
                        </div>
                        <div className="dash-para">
                            <p className="para-text">
                                We’re excited to have you on board. Your dashboard is now ready.
                            </p>
                            <p className="para-text">
                                Start managing your resident data with ease and discover the tools designed to simplify your workflow.
                            </p>
                        </div>
                    </div>
                    <div className="dashboard-section">
                        <div className="dashboard-card">
                            <div className="col-md-4">
                                <div className="card">
                                    <a href="/resident-dashboard" className="card-link">
                                        <div className="card-body">
                                            <img src="/images/resident-dash.png" className="dashboard-images" alt="Resident Dashboard" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <a href="/dashboard" className="card-link">
                                        <div className="card-body">
                                            <img src="/images/employee-dash.png" className="dashboard-images" alt="Staff Compliance" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <a href="/tutorial" className="card-link">
                                        <div className="card-body">
                                            <img src="/images/tutorial-dash.png" className="dashboard-images" alt="Tutorial" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dashboard-section">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body">
                                    <h5 className="card-title mb-4">Frequently Asked Questions</h5>
                                    <div className="faq-section">

                                        {/* Heading: Account & Login Issues */}
                                        <h3 className="mb-3 text-left p-3">
                                            <i className="fa-solid fa-user-lock me-2 text-primary"></i> Account & Login Issues
                                        </h3>
                                        <div className="accordion mb-4" id="accountLoginAccordion">
                                            {[
                                                { question: "I didn’t receive the invite email", answer: "Check your inbox and spam folder. The invite is sent to the registered email.", id: 1 },
                                                { question: "Invite link expired or invalid", answer: "Ask your admin to resend it. It remains valid for 30 days.", id: 2 },
                                                { question: "How to sign up without an invite", answer: "Only Facility Owners can use the signup page. Others need admin setup.", id: 3 },
                                                { question: "Forgot username", answer: "Provide your email and support will send your credentials.", id: 4 },
                                                { question: "Forgot password", answer: "Use \"Forgot Password\" or contact admin/support for reset.", id: 5 },
                                                { question: "Reset link not working", answer: "Try in an incognito window or request a new one.", id: 6 },
                                                { question: "Invalid credentials error", answer: "Double-check for typos in username or password.", id: 7 },
                                            ].map(({ question, answer, id }) => (
                                                <div className="accordion-item" key={id}>
                                                    <h2 className="accordion-header" id={`heading-account-${id}`}>
                                                        <button
                                                            className={`accordion-button ${openItem === id ? '' : 'collapsed'}`}
                                                            type="button"
                                                            onClick={() => handleToggle(id)}
                                                            aria-expanded={openItem === id}
                                                            aria-controls={`collapse-account-${id}`}
                                                        >
                                                            {question}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id={`collapse-account-${id}`}
                                                        className={`accordion-collapse ${openItem === id ? 'show' : 'collapse'}`}
                                                        aria-labelledby={`heading-account-${id}`}
                                                        data-bs-parent="#accountLoginAccordion"
                                                    >
                                                        <div className="accordion-body">
                                                            {answer}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {/* Heading: Course & Certification Issues */}
                                        <h3 className="mb-3 text-left p-3">
                                            <i className="fa-solid fa-graduation-cap me-2 text-success"></i> Course & Certification Issues
                                        </h3>
                                        <div className="accordion mb-4" id="courseAccordion">
                                            {[
                                                { question: "Completed course but no certificate", answer: "It's emailed to the Facility Admin and available in employee details.", id: 8 },
                                                { question: "Paid but course not showing", answer: "Check Facility Details or contact support.", id: 9 },
                                                { question: "Download certificate", answer: "Admins can download it from the profile.", id: 10 },
                                                { question: "Access certificate later", answer: "Yes, it's stored securely. Request it anytime.", id: 11 },
                                                { question: "Wrong name on certificate", answer: "Admins can update your name. We’ll reissue the certificate.", id: 12 },
                                            ].map(({ question, answer, id }) => (
                                                <div className="accordion-item" key={id}>
                                                    <h2 className="accordion-header" id={`heading-course-${id}`}>
                                                        <button
                                                            className={`accordion-button ${openItem === id ? '' : 'collapsed'}`}
                                                            type="button"
                                                            onClick={() => handleToggle(id)}
                                                            aria-expanded={openItem === id}
                                                            aria-controls={`collapse-course-${id}`}
                                                        >
                                                            {question}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id={`collapse-course-${id}`}
                                                        className={`accordion-collapse ${openItem === id ? 'show' : 'collapse'}`}
                                                        aria-labelledby={`heading-course-${id}`}
                                                        data-bs-parent="#courseAccordion"
                                                    >
                                                        <div className="accordion-body">
                                                            {answer}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {/* Heading: General Usage Issues */}
                                        <h3 className="mb-3 text-left p-3">
                                            <i className="fa-solid fa-gears me-2 text-warning"></i> General Usage Issues
                                        </h3>
                                        <div className="accordion mb-4" id="generalAccordion">
                                            {[
                                                { question: "Dashboard not visible", answer: "Use the left-hand menu for navigation.", id: 13 },
                                                { question: "Website is slow or down", answer: <>Visit our <a href="https://stats.uptimerobot.com/diLKdgcrLb" target="_blank" rel="noreferrer">Uptime Status</a>.</>, id: 14 },
                                                { question: "Module shows incomplete", answer: "Contact support with a screenshot for verification.", id: 15 },
                                            ].map(({ question, answer, id }) => (
                                                <div className="accordion-item" key={id}>
                                                    <h2 className="accordion-header" id={`heading-general-${id}`}>
                                                        <button
                                                            className={`accordion-button ${openItem === id ? '' : 'collapsed'}`}
                                                            type="button"
                                                            onClick={() => handleToggle(id)}
                                                            aria-expanded={openItem === id}
                                                            aria-controls={`collapse-general-${id}`}
                                                        >
                                                            {question}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id={`collapse-general-${id}`}
                                                        className={`accordion-collapse ${openItem === id ? 'show' : 'collapse'}`}
                                                        aria-labelledby={`heading-general-${id}`}
                                                        data-bs-parent="#generalAccordion"
                                                    >
                                                        <div className="accordion-body">
                                                            {answer}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </Master>
    );
};

export default DefaultDashboard;
