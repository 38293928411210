import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const HeaderFilterResident = ({
  searchFilter = "0",
  setSearchFilter,
  searchTerm = "",
  setSearchTerm,
  isPrintEnabled = false,
  setPdfUrl,
  isResidentDashboard = false,
  onFilterChange = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Synchronize the filter value with the URL query parameter
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterValue = params.get("filter") || "0"; // Default to "0" if no filter is set
    setSearchFilter(filterValue);
  }, [location.search, setSearchFilter]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    const params = new URLSearchParams(window.location.search);
    params.set("filter", searchFilter);
    params.set("search", value);
    navigate(`${window.location.pathname}?${params.toString()}`);
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setSearchFilter(value);

    // Call the shared filter update function if provided
    if (onFilterChange) {
      onFilterChange(value);
    }

    const params = new URLSearchParams(window.location.search);
    params.set("filter", value);
    params.set("search", searchTerm);

    if (window.location.pathname !== "/resident-dashboard") {
      navigate(`/resident-dashboard?${params.toString()}`); // Navigate to the new URL
    } else {
      navigate(`${window.location.pathname}?${params.toString()}`); // Update query params on the same page
    }
  };

  const renderFilterOptions = () => (
    <>
      <optgroup label="Admission/DC log">
        <option value="0">All Residents</option>
        <option value="1">Room number</option>
        <option value="2">New admissions current month</option>
        <option value="3">Discharges current month</option>
        <option value="4">New admissions previous month</option>
        <option value="5">Discharges previous month</option>
        <option value="6">Birthdays this month</option>
        <option value="7">Birthdays next month</option>
      </optgroup>
      <optgroup label="Compliance- AHCA">
        <option value="8">No Contract</option>
        <option value="9">No 1823 within 30 days of admission</option>
      </optgroup>
      <optgroup label="Health - Info">
        <option value="10">Elopement Risk</option>
        <option value="11">Dialysis</option>
        <option value="12">Special Diets</option>
        <option value="13">Special Diet:Calorie Controlled</option>
        <option value="14">Special Diet:Low Fat/Low Cholesterol</option>
        <option value="15">Special Diet:No Added Salt</option>
        <option value="16">Special Diet:Low Sugar</option>
        <option value="17">Special Diet:Other</option>
        <option value="18">Hospice</option>
        <option value="19">Requires Medical Assistance</option>
        <option value="20">Self-medicates</option>
      </optgroup>
      <optgroup label="Supplemental Programs">
        <option value="21">Long-term Care Approved</option>
        <option value="22">ACS Approved </option>
        <option value="23">OSS Approved</option>
        <option value="24">Insurance List</option>
        <option value="25">Rx Plan</option>
      </optgroup>
    </>
  );

  if (isResidentDashboard) {
    return (
      <div className="row mb-3">
        <div className="residentall col-md-4 mb-3">
          <select
            className="form-control form-control-lg"
            value={searchFilter}
            onChange={handleFilterChange}
            style={{ height: "39px", fontSize: "16px" }}
          >
            {renderFilterOptions()}
          </select>
        </div>
        <div className="residentall col-md-4 mb-3">
          <input
            type="text"
            placeholder="Search residents..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="form-control form-control-lg"
            style={{ height: "39px", fontSize: "16px" }}
          />
        </div>
        {isPrintEnabled && setPdfUrl && (
          <div className="col-md-4 mb-3">
            <a
              className="resident btn btn-info btn-print"
              target="_blank"
              rel="noopener noreferrer"
              href={setPdfUrl()}
            >
              Print
            </a>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="header-employee-search">
      <div className="employee-search-data">
        <div className="search-bar">
          <select
            className="a w-full p-2 border rounded mb-2"
            value={searchFilter}
            onChange={handleFilterChange}
            style={{ height: "39px", fontSize: "16px" }}
          >
            <option value="" disabled hidden>
              Filter Residents
            </option>
            {renderFilterOptions()}
          </select>
        </div>
      </div>
    </div>
  );
};

export default HeaderFilterResident;